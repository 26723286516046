<template>
<div class="w-100">

<cFullScreen :isFullScreen="fullScreen" class="w-100">

<template #fullScreen>

  <CDataTable v-if="items"
    :items="items"
    :fields="tableFields"
    :column-filter="columnFilter"
    :items-per-page-select="itemsPerPageSelect"
    :items-per-page="tableWatchList&&tableWatchList.itemsPerPage?tableWatchList.itemsPerPage:itemsPerPage"
    :hover="hover"
    :sorter="sorter"
    :striped="striped"
    :pagination="pagination"
    :loading="loading"
    :sorterValue="sorterValue"
    :tableFilter="tableFilter"
    :footer="footer"
    :header="header"
    
    @update:column-filter-value='updateColumnFilter'
    @filtered-items-change="getFilteredItemsChange"
    @page-change="onPageChange"
    @pagination-change="onPaginationChange"
    @row-clicked="onRowClicked"
    @update:sorter-value="updateSorterValue"
    class="d-flex flex-flow-wrap" 
    :activePage="activePage"
  >
     
       



    <template #over-table>
      
      <cManageColumns
        v-if="fields&&fields.length>0&&manageColumn"
        :lsFields="fields"
        @getFields="onSaveColumns"
      />

      <cPaginationLenOption v-if="paginationLenOption" :paginationWatchLs="tableWatchList" />
        <slot :name="'sub-over-table'" />

    </template>
    <!-- v-for="(x, slotName) in $scopedSlots" -->
     <template v-for="(s, slotName) in $scopedSlots"  #[slotName]="{item}">
         <!-- {{item}} -->
    <slot :name="slotName" :item="item" />
  </template>
    <!-- <slot v-for="(slotName) in fields" :name="slotName.key"></slot> -->
  </CDataTable>
  </template>
               </cFullScreen> 
               </div>
</template>
<script>
import { COMPONENT_NAME } from "../../util/constants";
import cPaginationLenOption from "../../components/dataTable/cPaginationLenOption";
import Logger from '../../services/logger';
import { LOG_TYPE } from '../../config/API_DATA';
import BaseConfiguration from '../../config/base';
import cManageColumns from "../../components/dataTable/cManageColumns";
import cFullScreen from "../../components/cFullScreen";

export default {
  name: COMPONENT_NAME.C_TABLE,
  components: {
    cPaginationLenOption,
    cManageColumns,
    cFullScreen
  },
  props: {
    tableWatchList: {
      type: Array|Object,
      default: null
    },
   
    items: {
      type: Array,
      default: undefined
    },
    fields: {
      type: Array,
      default: undefined
    },
    columnFilter: {
      type: Boolean,
      default: false
    },
    itemsPerPageSelect: {
      type: Boolean,
      default: false
    },
    itemsPerPage: {
      type: Number | String,
      default: 25
    },
    hover: {
      type: Boolean,
      default: false
    },
     striped: {
      type: Boolean,
      default: false
    },
    sorter: {
      type: Boolean | Object,
      default: false
    },
    pagination: {
      type: Boolean|Object,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    sorterValue: {
      type: Object,
    default: function() {
        return { column: null, asc: "true" };
      }
         
    },
    responsive:{
     type:Boolean,
     default:true
    },
    size:{
     type:String,
     default:undefined
    },
    tableFilter:{
      type:Boolean,
      default:false
    },
    manageColumn:{
      type:Boolean,
      default:false
    },
    paginationLenOption:{
      type:Boolean,
      default:false
    },
    fullScreen:{
      type:Boolean,
      default:true
    },
    footer:{
      type:Boolean,
      default:false
    },
     header:{
      type:Boolean,
      default:true
    },
    activePage:{
      type:Number,
      default:1
    },
    showColumnFilter:{
      type:Boolean,
      default:true
    },
  },
  data: () => {
    return {
      tableFields: null
    }
  },
    watch: {
       fields:{
        immediate: true, 
        deep: true,
    handler: function (newVal, oldVal) {
      let tableFields = this.fields;
       
       if(this.showColumnFilter==false){
          tableFields.forEach(field => {
                        if(field.hasOwnProperty('filter')){
                          field.filter=false;
                            this.$emit('isCustomFilter',false);
                          
                       }else{
                         field['filter']=false;
                       }
                    });

                    
        }
        
        this.tableFields =  tableFields;
      }
      },
  },
  methods: {  
    /*handleScroll(){
      console.log("dfs");
    } ,   */



    updateColumnFilter(pFilterVal) {
        try {
          if(BaseConfiguration.isDebug) console.log("updateColumnFilter");

          this.$emit('update:column-filter-value',pFilterVal)
      
        } catch (err) {
                let body = Logger.buildRequest(
                  FILE_NAME.C_TABLE,
                  "error in updateColumnFilter",
                  err.toString(),
                  "updateColumnFilter",
                  LOG_TYPE.ERROR
                );
                Logger.getResponse(body);
              }
       
        }, 
    updateSorterValue(pSorterValue) {
        try {
          if(BaseConfiguration.isDebug) console.log("updateSorterValue");
          this.$emit('update-sorter-value',pSorterValue)
        } catch (err) {
                let body = Logger.buildRequest(
                  COMPONENT_NAME.C_TABLE,
                  "error in updateSorterValue",
                  err.toString(),
                  "myFunctionupdateSorterValueName",
                  LOG_TYPE.ERROR
                );
                Logger.getResponse(body);
              }
        
        }, 
    onRowClicked(pRowObj) {
        try {
          if(BaseConfiguration.isDebug) console.log("onRowClicked");
               this.$emit('row-clicked',pRowObj)

    
        } catch (err) {
                let body = Logger.buildRequest(
                  COMPONENT_NAME.C_TABLE,
                  "error in onRowClicked",
                  err.toString(),
                  "onRowClicked",
                  LOG_TYPE.ERROR
                );
                Logger.getResponse(body);
              }
          
        }, 
      onSaveColumns(pFields) {
    try {
      if(BaseConfiguration.isDebug) console.log("onSaveColumns");
    //   this.reportField=pFields;
      this.$emit('getFields',pFields)

    } catch (err) {
            let body = Logger.buildRequest(
             COMPONENT_NAME.C_TABLE,
              "error in onSaveColumns",
              err.toString(),
              "onSaveColumns",
              LOG_TYPE.ERROR
            );
            Logger.getResponse(body);
          }
     
    }, 
    getFilteredItemsChange(pFilteredItems) {
      try {
        if (BaseConfiguration.isDebug) console.log("getFilteredItemsChange");
        this.$emit('filtered-items-change',pFilteredItems)
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_TABLE,
          "error in getFilteredItemsChange",
          err.toString(),
          "getFilteredItemsChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onPageChange(pPageNum) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPageChange");
        this.$emit('page-change',pPageNum)
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_TABLE,
          "error in onPageChange",
          err.toString(),
          "onPageChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onPaginationChange(pItemsPerPage) {
      try {
        if (BaseConfiguration.isDebug) console.log("onPaginationChange");
        this.$emit('pagination-change',pItemsPerPage)
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_TABLE,
          "error in onPaginationChange",
          err.toString(),
          "onPaginationChange",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    }
  },
  /*updated(){
    var table = document.getElementById("bala");
    if(table)
      table.addEventListener("scroll", function (event) {
        console.log("vba");
      });
    //window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    var table = document.getElementById("bala");
    //if(table)
    //table.removeEventListener('scroll', this.handleScroll);
  },*/
};

//  onTableScroll(){
//         console.log("sdf");
//     },
</script>