<template>
  <div>
    <fullscreen
      ref="fullscreen"
      @change="fullscreenChange"
      :background="'#ffffff'"
      :style="fullscreen ? 'padding : 0px 10px;' : 'padding : 0px;'"
    >
      <div class="d-flex align-center justify-content-end fs-icon-wrap">
        <img
          v-if="isFullScreen"
          :key="fullscreen ? 'exit' : 'view'"
          :src="fullscreen ? getImage('small_screen') : getImage('full_screen')"
          alt
          @click="toggle"
          class="fullscreen-btn translatey-0px mr-0"
          v-c-tooltip.hover="{
            content: fullscreen
              ? 'Click to exit full screen'
              : 'Click to view in full screen',
            active: false,
          }"
        />
      </div>
      <slot name="fullScreen"></slot>
    </fullscreen>
  </div>
</template>

<script>
import { COMPONENT_NAME } from "../util/constants";
import Logger from "../services/logger";
import { LOG_TYPE } from "../config/API_DATA";

export default {
  name: COMPONENT_NAME.C_FULLSCREEN,

  props: {
    isFullScreen: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      fullscreen: false,
    };
  },

  methods: {
    toggle() {
      //  this.$emit("toggle")
      this.$refs["fullscreen"].toggle();
      // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
      // this.$emit("screenChange", fullscreen);
    },

    getImage(pCode) {
      try {
        var images = require.context(
          "@imgPath/img/svg/icons/",
          false,
          /\.svg$/
        );
        return images("./" + pCode + ".svg");
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_FULLSCREEN,
          "error while getting image",
          err.toString(),
          " getImage",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
  },
};
</script>