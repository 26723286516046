<template>
  <div class="pagination-count" v-if="isReportPage?paginationWatchLs.totalItemCount!=0:paginationWatchLs&&paginationWatchLs.filteredlistCount&&paginationWatchLs.filteredlistCount!=0">
    <span
      v-if="!isReportPage"
    >{{getStartValueCount()}} - {{getFilteredListPerPage()}} {{$t('cDataTable.cPaginationLenOption.of')}} {{paginationWatchLs.filteredlistCount}}</span>
    <span
      v-else
    >{{getStartValCountForReport()}} - {{getFilteredLsPerPageForRep()}} {{$t('cDataTable.cPaginationLenOption.of')}} {{paginationWatchLs.totalItemCount}}</span>
  </div>
</template>
<script>
import {
  COMPONENT_NAME,

} from "../../util/constants";
import Logger from "../../services/logger";
import BaseConfiguration from "../../config/base";
import {
  LOG_TYPE,
} from "../../config/API_DATA";
export default {
  props: {
    paginationWatchLs: {
      type: Object,
      default: null
    },
    isReportPage: {
      type: Boolean,
      default: false
    }
  },
  // watch:{
  //    paginationWatchLs:{
  //       immediate: false, 
  //       deep: true,
  //   handler: function (newVal, oldVal) {
   
  //     this.paginationWatchLs=newVal;
  //     // this.cSystemDepositWatchList.filteredlistCount=this.lsPostpaidStatements.length;
  //     // let count=0;
  //     // for(let i=0;i<this.lsPostpaidStatements.length;i++){
  //     //  count= count+this.lsPostpaidStatements[i].list.length;
  //     // }
    
  //     }
  //     },
  // },
  methods: {
    getStartValueCount() {
      let retValue;
      try {
       
        if (BaseConfiguration.isDebug) console.log("getStartValueCount");
        if (this.paginationWatchLs.filteredlistCount == 0) {
          retValue = 0;
        } else {
          if (
            this.paginationWatchLs.itemsPerPage <
            this.paginationWatchLs.filteredlistCount
          ) {
            retValue =
              (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage;
            retValue = retValue + 1;
          } else {
            retValue = 1;
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_PAGINATION_LEN_OPTION,
          "error in getStartValueCount",
          err.toString(),
          "getStartValueCount",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getFilteredListPerPage() {
      let retValue;

      try {
        if (BaseConfiguration.isDebug) console.log("getFilteredListPerPage");
        if (
          this.paginationWatchLs.itemsPerPage <
          this.paginationWatchLs.filteredlistCount
        ) {
          if (
            this.paginationWatchLs.currentPageNum *
              this.paginationWatchLs.itemsPerPage >
            this.paginationWatchLs.filteredlistCount
          ) {
            retValue = this.paginationWatchLs.filteredlistCount;
          } else {
            retValue =
              this.paginationWatchLs.currentPageNum *
              this.paginationWatchLs.itemsPerPage;
          }
        } else {
          retValue = this.paginationWatchLs.filteredlistCount;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_PAGINATION_LEN_OPTION,
          "error in getFilteredListPerPage",
          err.toString(),
          "getFilteredListPerPage",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getFilteredLsPerPageForRep() {
      let retValue;

      try {
        if (BaseConfiguration.isDebug)
          if (BaseConfiguration.isDebug) console.log("getFilteredLsPerPageForRep");
        if (this.paginationWatchLs.orinalReportRes) {
          let j = 0;
          for (
            let i =
              (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage;
            i < this.paginationWatchLs.orinalReportRes.length;
            i++
          ) {
            j++;
            if (j == this.paginationWatchLs.itemsPerPage) {
              retValue = this.paginationWatchLs.orinalReportRes[i]
                .paginationLenVal;
              break;
            }
            if (
              this.paginationWatchLs.orinalReportRes[i].paginationLenVal == ""
            ) {
              if (
                i != 0 &&
                this.paginationWatchLs.orinalReportRes[i - 1].paginationLenVal
              ) {
                retValue = this.paginationWatchLs.orinalReportRes[i - 1]
                  .paginationLenVal;
                break;
              }
            }
          }
        } else {
          retValue = 0;
        }

       
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_PAGINATION_LEN_OPTION,
          "error in getFilteredLsPerPageForRep",
          err.toString(),
          "getFilteredLsPerPageForRep",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    getStartValCountForReport() {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("getStartValCountForReport");
        if (
          this.paginationWatchLs.orinalReportRes &&
          this.paginationWatchLs.orinalReportRes[
            (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage
          ] &&
          this.paginationWatchLs.orinalReportRes[
            (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage
          ].paginationLenVal &&
          this.paginationWatchLs.orinalReportRes[
            (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage
          ].paginationLenVal != ""
        ) {
          retValue = this.paginationWatchLs.orinalReportRes[
            (this.paginationWatchLs.currentPageNum - 1) *
              this.paginationWatchLs.itemsPerPage
          ].paginationLenVal;
        } else {
          retValue = 0;
        }
       
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_PAGINATION_LEN_OPTION,
          "error in getStartValCountForReport",
          err.toString(),
          "getStartValCountForReport",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    }
  }
};
</script>