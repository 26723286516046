<template>
    <div class="setting-t-t">
        <div @click="showMangeColModal" class="c-pointer setting-wrap-icon">

            <CIcon style="fill:#c7bca9" name="cil-settings" size="lg" v-c-tooltip.hover="{ content:$t('cManageColumns.manageColumns') , active:false }"/>
        </div>
        <CModal
                :show.sync="isShowManageColModal"
                centered
                :backdrop="true"
                :closeOnBackdrop="false"
                :id="'modal2'"
                :title="$t('cManageColumns.manageColumns')"
                :data-dismiss="'modal'"
        >
            <template #header-wrapper>
                <CCardHeader>
                    <div class="d-flex justify-content-between">
                        <h4>{{$t('cManageColumns.manageColumns')}}</h4>
                        <div class="close">
                            <img
                                    @click="toggleManageColModal"
                                    src="@imgPath/img/svg/icons/cross.svg"
                                    height="18px"
                                    alt
                            />
                        </div>
                    </div>
                </CCardHeader>
            </template>
            <div>
                <div v-for="(fieldObj,index) in lsColumnsToShow" :key="index">
                  
                    <div class="d-flex align-item-center b-radius-3px border-gray-1px mb-2">
                        <div class="p-2 w-100 ">
                            {{fieldObj.label}}
                        </div>
                        <div class="p-3 rest-transform">
                            <!-- <div
                                    v-if="index<=1||fieldObj.key=='actions'||fieldObj.key=='action'"

                            > -->
                            <div
                                    v-if="fieldObj.isLocked==true"

                            >
                                <CIcon color="#cd0606" name="cil-lock-locked" size="lg"/>
                            </div>
                            <div
                                    v-if="fieldObj.isLocked==false"
                                    class="d-flex align-item-center c-pointer"

                            >
                                <img @click="onRemoveColumn(index)" src="@imgPath/img/svg/icons/cross-red.svg" alt
                                     width="13px"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="getColumnsForAdd(orgColumnFields,lsColumnsToShow).length>0">
                    <multiselect
                            label="label"
                            :allow-empty="false"
                            :options="getColumnsForAdd(orgColumnFields,lsColumnsToShow)"
                            :track-by="'key'"
                            :showLabels="false"
                            :placeholder="$t('cManageColumns.addNewColumn')"
                            @input="onAddColumn"
                    >
                        <template #noResult>
                            <strong>{{$t('cManageColumns.listEmpty')}}</strong>
                        </template>
                    </multiselect>
                </div>
            </div>

            <template #footer-wrapper>
                <CCardFooter class="d-flex justify-content-end">
                    <div>
                        <CRow>
                            <CCol class="d-flex">
                                <CButton
                                        color="danger"
                                        @click="toggleManageColModal"
                                        class="mr-10px ml-auto"
                                >{{$t('currency.cancel')}}
                                </CButton>

                                <CButton @click="onSaveSelColumns" color="success" class="min-w-125">{{$t('currency.save') }}</CButton>
                            </CCol>
                        </CRow>
                    </div>
                </CCardFooter>
            </template>
        </CModal>
    </div>
</template>
<script>
import {
  COMPONENT_NAME,
  MANAGE_COLUMNS
} from "../../util/constants";
import Logger from "../../services/logger";
import BaseConfiguration from "../../config/base";
import {
  LOG_TYPE,
} from "../../config/API_DATA";
import { differenceBy, cloneData } from "../../util/util";
import Vue from "vue";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import {
  getManageColumnsListFromLocal,
  setManageTableColumns
} from "../../applocalstorage/localStorage";

export default {
  name: COMPONENT_NAME.C_MANAGE_COLUMNS,
  components: {
    Multiselect
  },
  props: {
    lsFields: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    }
  },
  data() {
    return {
      isShowManageColModal: false,
      orgColumnFields: [],
      lsColumnsToShow: [],
      isActionKeyExist: false
    };
  },

  computed: {
    ...mapGetters("sDashBoard", {
      USER_ID: "GET_USER_ID"
    })
  },
  methods: {
    storeManagedColumns(pLsColumns, pTableName, P_GET_LIST_MANAGE_COLUMN) {
      let retValue = cloneData(P_GET_LIST_MANAGE_COLUMN);
      try {
        if (BaseConfiguration.isDebug) console.log("storeManagedColumns");
        if (
          P_GET_LIST_MANAGE_COLUMN &&
          P_GET_LIST_MANAGE_COLUMN.constructor == Object
        ) {
          if (P_GET_LIST_MANAGE_COLUMN[pTableName]) {
            retValue[pTableName] = pLsColumns;
          } else {
            retValue[pTableName] = pLsColumns;
          }
        } else {
          retValue = {};
          retValue[pTableName] = pLsColumns;
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in storeManagedColumns",
          err.toString(),
          "storeManagedColumns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
    setStatusToFields(pLsColumnsToShow, pOrgColumnFields) {
      let retValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("setStatusToFields");
        let lsVisible = cloneData(pLsColumnsToShow);
        for (let j = 0; j < lsVisible.length; j++) {
          if (
            lsVisible[j].isLocked == true
          ) {
            lsVisible[j].status = MANAGE_COLUMNS.LOCK;
          } else {
            lsVisible[j].status = MANAGE_COLUMNS.VISIBLE;
          }
        }
        retValue = lsVisible;

        let lsInvisible = differenceBy(
          pOrgColumnFields,
          pLsColumnsToShow,
          "key"
        );
        for (let i = 0; i < lsInvisible.length; i++) {
          lsInvisible[i].status = 0;
          retValue.push(lsInvisible[i]);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in setStatusToFields",
          err.toString(),
          "setStatusToFields",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
    onSaveSelColumns() {
      try {
        if (BaseConfiguration.isDebug) console.log("onSaveSelColumns");
        let lsFieldsWithStatus = this.setStatusToFields(
          this.lsColumnsToShow,
          this.orgColumnFields
        );
        let lsManageColumnFrmLocal = JSON.parse(
          getManageColumnsListFromLocal(this.USER_ID)
        );
        setManageTableColumns(
          JSON.stringify(
            this.storeManagedColumns(
              lsFieldsWithStatus,
              this.$router.currentRoute.name,
              lsManageColumnFrmLocal
            )
          ),
          this.USER_ID
        );
        this.emitManagedColumns(cloneData(this.lsColumnsToShow));


        this.isShowManageColModal = false;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in onSaveSelColumns",
          err.toString(),
          "onSaveSelColumns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    getColumnsForAdd(pLsOrgColumns, pLsFieldsToShow) {
      let retValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getColumnsForAdd");
        if (pLsOrgColumns && pLsFieldsToShow) {
          retValue = differenceBy(pLsOrgColumns, pLsFieldsToShow, "key");
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in getColumnsForAdd",
          err.toString(),
          "getColumnsForAdd",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },
    onAddColumn(pSelField) {
      try {
        if (BaseConfiguration.isDebug) console.log("onAddColumn");
        let lsColumns = cloneData(this.lsColumnsToShow);
        // if (this.isActionKeyExist) {
        //   lsColumns.splice(lsColumns.length - 1, 0, pSelField);
        // } else {
          lsColumns.push(pSelField);
        // }

        this.lsColumnsToShow = [];
        let that = this;

        Vue.nextTick(function() {
          that.lsColumnsToShow = lsColumns;
        });
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in onAddColumn",
          err.toString(),
          "onAddColumn",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    onRemoveColumn(pIndex) {
      try {
        if (BaseConfiguration.isDebug) console.log("onRemoveColumn");
        if (this.lsColumnsToShow.length > 1) {
          let lsColumns = [];
          for (let i = 0; i < this.lsColumnsToShow.length; i++) {
            if (pIndex != i) {
              lsColumns.push(this.lsColumnsToShow[i]);
            }
          }
          this.lsColumnsToShow = [];
          let that = this;

          Vue.nextTick(function() {
            that.lsColumnsToShow = lsColumns;
          });
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in onRemoveColumn",
          err.toString(),
          "onRemoveColumn",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    toggleManageColModal() {
      try {
        if (BaseConfiguration.isDebug) console.log("toggleManageColModal");
        this.isShowManageColModal = !this.isShowManageColModal;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in toggleManageColModal",
          err.toString(),
          "toggleManageColModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },
    showMangeColModal() {
      try {
        if (BaseConfiguration.isDebug) console.log("showMangeColModal");
        this.initialFormatting();
        this.isShowManageColModal = true;
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in showMangeColModal",
          err.toString(),
          "showMangeColModal",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

  //it returns a list with status 'lock & visible'
    getVisibleFieldsFromStore(lsFieldsFromStore, pLsOrgColumns) {
      let retValue = [];
      try {
        if (BaseConfiguration.isDebug) console.log("getVisibleFieldsFromStore");
        
        for (let i = 0; i < lsFieldsFromStore.length; i++) {
          if (
            lsFieldsFromStore[i].status == MANAGE_COLUMNS.LOCK ||
            lsFieldsFromStore[i].status == MANAGE_COLUMNS.VISIBLE
          ) {
            loop2: for (let j = 0; j < pLsOrgColumns.length; j++) {
              if (pLsOrgColumns[j].key == lsFieldsFromStore[i].key) {
                retValue.push(pLsOrgColumns[j]);
                break loop2;
              }
            }
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in getVisibleFieldsFromStore",
          err.toString(),
          "getVisibleFieldsFromStore",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    checkItIncludesActionKey(pLsOrgColumns) {
      let retValue;

      try {
        if (BaseConfiguration.isDebug) console.log("checkItIncludesActionKey");
        for (let i = pLsOrgColumns.length - 1; i >= 0; i--) {
          if (
            pLsOrgColumns[i].key == "actions" ||
            pLsOrgColumns[i].key == "action"
          ) {
            retValue = true;
            break;
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in checkItIncludesActionKey",
          err.toString(),
          "checkItIncludesActionKey",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

    ///to change action or actions key to first or last by help of boolean (action-considered as lock status)
    changeActionKeyOrder(pLsColumns, pIsFirst) {
      let retValue;
      try {
        if (BaseConfiguration.isDebug) console.log("changeActionKeyOrder");
        let orderedList = cloneData(pLsColumns);
        let newIndex = pIsFirst ? 2 : orderedList.length - 1;
        for (let i = 0; i < orderedList.length; i++) {
          if (
            orderedList[i].key == "action" ||
            orderedList[i].key == "actions"
          ) {
            let oldIndex = i;

            if (newIndex >= orderedList.length) {
              var k = newIndex - orderedList.length + 1;
              while (k--) {
                orderedList.push(undefined);
              }
            }
            orderedList.splice(newIndex, 0, orderedList.splice(oldIndex, 1)[0]);
            retValue = orderedList;
            break;
          }
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in changeActionKeyOrder",
          err.toString(),
          "changeActionKeyOrder",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
      return retValue;
    },

///to emit columns by ordering action or actions key as last element
    emitManagedColumns(pLsColumns) {
      try {
        if (BaseConfiguration.isDebug) console.log("emitManagedColumns");
        let lsFields = this.changeActionKeyOrder(cloneData(pLsColumns), false);
        if (lsFields) {
          this.$emit("getFields", lsFields);
        } else {
          this.$emit("getFields", pLsColumns);
        }
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in emitManagedColumns",
          err.toString(),
          "emitManagedColumns",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    },

    initialFormatting() {
      try {
        if (BaseConfiguration.isDebug) console.log("initialFormatting");
        let lsManageColumnFrmLocal = JSON.parse(
          getManageColumnsListFromLocal(this.USER_ID)
        );

        if (
          lsManageColumnFrmLocal &&
          lsManageColumnFrmLocal[this.$router.currentRoute.name]
        ) {
          this.lsColumnsToShow = this.getVisibleFieldsFromStore(
            lsManageColumnFrmLocal[this.$router.currentRoute.name],
            this.orgColumnFields
          );
          let lsColumnsToShow = this.changeActionKeyOrder(
            cloneData(this.lsColumnsToShow),
            true
          );
          if (lsColumnsToShow) {
            this.lsColumnsToShow = lsColumnsToShow;
          }
          this.emitManagedColumns(cloneData(this.lsColumnsToShow));


        } else {
          let orgColumnFields = JSON.parse(
            JSON.stringify(this.orgColumnFields)
          );
          if (orgColumnFields) {
            let lsColumnsToShow = this.changeActionKeyOrder(
              orgColumnFields,
              true
            );
            if (lsColumnsToShow) {
              this.lsColumnsToShow = lsColumnsToShow;
            } else {
              this.lsColumnsToShow = orgColumnFields;
            }
          }
        }
        this.isActionKeyExist = this.checkItIncludesActionKey(
          this.orgColumnFields
        );
      } catch (err) {
        let body = Logger.buildRequest(
          COMPONENT_NAME.C_MANAGE_COLUMNS,
          "error in initialFormatting",
          err.toString(),
          "initialFormatting",
          LOG_TYPE.ERROR
        );
        Logger.getResponse(body);
      }
    }
  },
  created() {
    try {
      if (BaseConfiguration.isDebug) console.log("created");
      this.orgColumnFields = cloneData(this.lsFields);
      this.initialFormatting();
    } catch (err) {
      let body = Logger.buildRequest(
        COMPONENT_NAME.C_MANAGE_COLUMNS,
        "error in created",
        err.toString(),
        "created",
        LOG_TYPE.ERROR
      );
      Logger.getResponse(body);
    }
  }
};
</script>